import { combineReducers } from 'redux';
// Import types here
import { ConfigState } from './config/types';
import { MessagesState } from './messages/types';
import { PerformanceState } from './performance/types';
import { RatingsState } from './ratings/types';
import { UserState } from './user/types';
import { ValueCalculatorState } from './value_calculator/types';

// Import reducers here
import config from './config';
import messages from './messages';
import performanceData from './performance';
import ratings from './ratings';
import user from './user';
import valueCalculator from './value_calculator';
export interface StoreState {
    user: UserState;
    messages: MessagesState;
    config: ConfigState;
    ratings: RatingsState;
    valueCalculator: ValueCalculatorState;
    performanceData: PerformanceState;
}

export default combineReducers( {
    // Insert reducers here
    user
    , messages
    , config
    , ratings
    , valueCalculator
    , performanceData
} );

// export type RootState = ReturnType<typeof combineReducers>;
