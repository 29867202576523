'use client';

import {
    ReactNode
    , Suspense
    , useEffect
    , useState
} from 'react';

// Libraries
import {
    QueryClient
    , QueryClientProvider
} from '@tanstack/react-query';
import LogRocket from 'logrocket';
import dynamic from 'next/dynamic';
import {
    usePathname
    , useRouter
} from 'next/navigation';
import Script from 'next/script';
import { ErrorBoundary } from 'react-error-boundary';
import { Provider } from 'react-redux';
import { ampli } from '../src/ampli/index';
import {
    Configuration
    , PublicClientApplication
} from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
// MUI
import {
    Box
    , CssBaseline
    , ThemeProvider
    , useMediaQuery
} from '@mui/material';
import { AppRouterCacheProvider } from '@mui/material-nextjs/v14-appRouter';
import { LicenseInfo } from '@mui/x-license';
import { PersistGate } from 'redux-persist/integration/react';
import { NuqsAdapter } from 'nuqs/adapters/next/app';

// Components
import { OfflineSnackbar } from '@/components/OfflineSnackbar/OfflineSnackbar';
import { OnlineSnackbar } from '@/components/OnlineSnackbar/OnlineSnackbar';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { GlobalErrorFallback } from 'src/components/GlobalErrorFallback/GlobalErrorFallback';

// Ducks
import { setAllConfig } from '@/ducks/config';
import {
    persistor
    , useStore
} from '@/ducks/store';

// Utils
import { makeAPICall } from '@/utils/axiosInstance';

// Integrations
import * as gtag from '@/integrations/gtag';
import * as amplitude from '@amplitude/analytics-browser';

// Theme
import '@/theme/chat/attachmentStyles.css';
import '@/theme/chat/generalStyles.css';
import '@/theme/chat/messageStyles.css';
import '@/theme/index.css';
import theme from '@/theme/theme';

import { SnackbarProvider } from 'notistack';

// Atoms
import {
    useShouldShowAmpliLogsToggle
    , useShouldShowReactQueryDevToolsToggle
} from './atom';
import { BaseSnackbar } from './shared/components/Snackbars/BaseSnackbar/BaseSnackbar';

const ReactQueryDevtoolsProduction = dynamic( () => import( '@tanstack/react-query-devtools/build/modern/production.js' )
    .then( d => d.ReactQueryDevtools ), {
    loading: () => null
} );

const queryClient = new QueryClient( {
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: process.env.NODE_ENV === 'production'
        }
    }
} );

// MSAL configuration (Microsoft)
const configuration: Configuration = {
    auth: {
        clientId: process.env.NEXT_PUBLIC_MICROSOFT_CLIENT_ID as string
    }
};

const pca = new PublicClientApplication( configuration );

export type BranchData = {
    data_parsed: {
        email: string;
        businessUser: {
            businessUserId: number;
            businessId: number;
            firstName: string;
            lastName: string;
            businessName: string;
            email: string;
            isExistingUser: boolean;
        };
        referringBusinessId: number;
        referringBusinessUserEmail: string;
        referringBusinessUserId: number;
        businessReferralTrackerId: number;
    };
    referring_link: string | undefined;
};

declare global {
    // eslint-disable-next-line no-unused-vars
    interface Window {
        recaptchaCallback: any;
        HubSpotConversations: any;
        hsConversationsSettings: any;
        companyVariables: any;
        branch: { init: ( key: string | undefined, callback: ( ( err: Error, data: BranchData ) => void ) ) => void };
        gtag: any;
        $zopim: {
            livechat: {
                authenticate: ( {
                    jwtFn
                }: {
                    jwtFn: ( callback: ( jwt: string ) => void ) => Promise<void>;
                } ) => Promise<void>;
            };
        } | any;
        zE: any;
        zESettings: any;
        gapi: any;
        google: {
            maps: {
                places: {
                    Autocomplete: any;
                    event: {
                        trigger: () => any;
                    };
                    AutocompleteService: any;

                };
            };
            accounts: {
                id: {
                    renderButton: ( arg0: HTMLElement | null, arg1: { theme: string; size: string; width: string } ) => any;
                    initialize: ( params: { client_id: string; callback: ( response: any ) => any } ) => any;
                    prompt: () => any;
                };
                oauth2: any;

            };
        };
        Cypress: any;
        Stripe: any;
        _wq: any;
        grecaptcha: {
            reset: () => {};
        };
        store: any;
        AppcuesSettings: any;
        Appcues: any;
    }
}

declare module 'notistack' {
    interface OptionsObject {
        icon?: ReactNode;
        showCloseButton?: boolean;
        closeHandler?: () => void;
        actionText?: string;
        actionHandler?: () => void;
    }

    interface VariantOverrides {
        info: false;
    }
}

export default function RootLayout ( {
    children
}: {
  children: React.ReactNode;
} ) {
    const store = useStore( undefined );

    LicenseInfo.setLicenseKey( process.env.NEXT_PUBLIC_MUI_X_LICENSE_KEY as string ); // Sets Mui-X license key for the app

    const router = useRouter();
    const pathname = usePathname();
    const isWindowDefined = typeof window !== 'undefined';
    const isAppcuesDefined = isWindowDefined && window.Appcues;

    const [ canonicalUrl, setCanonicalUrl ] = useState( '' );
    const [ isUserOffline, setUserOffline ] = useState( false );
    const [ isUserOnline, setUserOnline ] = useState( false );
    let offlineTimer: NodeJS.Timeout;

    const isMdDown = useMediaQuery( theme.breakpoints.down( 'md' ) );

    useEffect( () => {
        if ( isWindowDefined ) {
            getConfig();
            setCanonicalUrl( ( `${ window?.location.origin }${ pathname === '/' ? '' : pathname }` ).split( '?' )[ 0 ] );
        }
    }, [ pathname, isWindowDefined ] );

    const getConfig = async () => {
        try {
            const results = await makeAPICall( {
                method: 'GET'
                , url: `/config?environment=${ process.env.NEXT_PUBLIC_ENVIRONMENT || 'staging' }&platform=frontend`
            } );
            if ( results?.length ) {
                const config: { [ key: string ]: any } = {};
                for ( const result of results ) {
                    config[ result.varName ] = result.varValue;
                }
                store.dispatch( setAllConfig( config ) );
                if ( config.maintenanceOutage || process.env.NEXT_PUBLIC_MAINTENANCE_MODE === 'true' ) {
                    router.push( '/maintenance' );
                }
            }
        } catch ( e ) {
            if ( e && e instanceof Error ) {
                console.error( `Error retrieving maintenance config - ${ e.message }` );
                if ( process.env.NEXT_PUBLIC_MAINTENANCE_MODE === 'true' ) {
                    router.push( '/maintenance' );
                    return;
                }
            }
        }
    };

    useEffect( () => {
        if ( isWindowDefined ) {
            gtag.pageview( new URL( window.location.href ) );
        }
    }, [ pathname, isWindowDefined ] );

    const handleUserOffline = () => {
        LogRocket.track( 'User is offline!' );
        offlineTimer = setTimeout( () => {
            setUserOffline( true );
        }, 1000 );
    };

    const handleUserOnline = () => {
        clearTimeout( offlineTimer );
        setUserOnline( true );
        setUserOffline( false );
    };

    useEffect( () => {
        if ( isWindowDefined ) {
            window.addEventListener( 'offline', handleUserOffline );
            window.addEventListener( 'online', handleUserOnline );
            window.AppcuesSettings = { enableURLDetection: true };
        }
        return () => {
            if ( isWindowDefined ) {
                window.removeEventListener( 'offline', () => {} );
                window.removeEventListener( 'online', () => {} );
            }
        };
    }, [ isWindowDefined ] );

    const [ shouldShowReactQueryDevTools ] = useShouldShowReactQueryDevToolsToggle();
    const [ showAmplitudeLogs ] = useShouldShowAmpliLogsToggle();

    useEffect( () => {
        if (
            process.env.NEXT_PUBLIC_ENVIRONMENT
            && process.env.NEXT_PUBLIC_ENVIRONMENT === 'production'
        ) {
            LogRocket.init( 'nrwn2c/business-portal-next-prod', {
                network: {
                    requestSanitizer: request => {
                        if ( request?.body ) {
                            try {
                                const body = JSON.parse( request.body );
                                if ( body && 'password' in body ) {
                                    request.body = '';
                                }
                            } catch ( error ) {
                                request.body = '';
                            }
                        }
                        return request;
                    }
                }
                , release: process.env.NEXT_PUBLIC_LOGROCKET_VERSION ?? 'unknown'
                , console: {
                    shouldAggregateConsoleErrors: process.env.NEXT_PUBLIC_LOGROCKET_SURFACE_CONSOLE_ERRORS === 'true'
                }
            } );
            ampli.load( {
                environment: 'production'
                , client: {
                    configuration: {
                        minIdLength: 1
                        , serverUrl: '/amplitude'
                    }
                }
            } );
        } else if (
            process.env.NEXT_PUBLIC_ENVIRONMENT
            && process.env.NEXT_PUBLIC_ENVIRONMENT === 'qa'
        ) {
            LogRocket.init( 'nrwn2c/business-portal-next-qa', {
                network: {
                    requestSanitizer: request => {
                        if ( request?.body ) {
                            try {
                                const body = JSON.parse( request.body );
                                if ( body && 'password' in body ) {
                                    request.body = '';
                                }
                            } catch ( error ) {
                                request.body = '';
                            }
                        }
                        return request;
                    }
                }
                , release: process.env.NEXT_PUBLIC_LOGROCKET_VERSION ?? 'unknown'
            } );
            ampli.load( {
                environment: 'development'
                , client: {
                    configuration: {
                        minIdLength: 1
                        , logLevel: showAmplitudeLogs ? amplitude.Types.LogLevel.Debug : undefined
                        , serverUrl: '/amplitude'
                    }
                }
            } );
        } else {
            LogRocket.init( 'nrwn2c/business-portal-next-staging', {
                network: {
                    requestSanitizer: request => {
                        if ( request?.body ) {
                            try {
                                const body = JSON.parse( request.body );
                                if ( body && 'password' in body ) {
                                    request.body = '';
                                }
                            } catch ( error ) {
                                request.body = '';
                            }
                        }
                        return request;
                    }
                }
                , release: process.env.NEXT_PUBLIC_LOGROCKET_VERSION ?? 'unknown'
            } );
            ampli.load( {
                environment: 'development'
                , client: {
                    configuration: {
                        minIdLength: 1
                        , logLevel: showAmplitudeLogs ? amplitude.Types.LogLevel.Debug : undefined
                        , serverUrl: '/amplitude'
                    }
                }
            } );
        }
    }, [ isWindowDefined, showAmplitudeLogs ] );

    if ( typeof window !== 'undefined' ) {
        // eslint-disable-next-line react-compiler/react-compiler
        window.store = store;
    }

    const getFavicon = () => {
        if ( process.env.NODE_ENV === 'development' && process.env.NEXT_PUBLIC_ENVIRONMENT === 'qa' ) {
            return (
                <link
                    rel='shortcut icon'
                    href='/favicon-localqa.ico'
                />
            );
        } else if ( process.env.NODE_ENV === 'development' && process.env.NEXT_PUBLIC_ENVIRONMENT === 'staging' ) {
            return (
                <link
                    rel='shortcut icon'
                    href='/favicon-localstaging.ico'
                />
            );
        } else if ( process.env.NEXT_PUBLIC_ENVIRONMENT === 'qa' ) {
            return (
                <link
                    rel='shortcut icon'
                    href='/favicon-qa.ico'
                />
            );
        } else if ( process.env.NEXT_PUBLIC_ENVIRONMENT === 'staging' ) {
            return (
                <link
                    rel='shortcut icon'
                    href='/favicon-staging.ico'
                />
            );
        } else {
            return (
                <link
                    rel='shortcut icon'
                    href='/favicon.ico'
                />
            );
        }
    };

    return (
        <html lang='en'>
            <head>
                { getFavicon() }
                <link
                    rel='preconnect'
                    href='https://fonts.googleapis.com'
                />
                <link
                    rel='preconnect'
                    href='https://fonts.gstatic.com'
                />
                <link
                    rel='stylesheet'
                    href='https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;1,400&display=swap'
                />
                <link
                    rel='stylesheet'
                    href='https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600&display=swap'
                />
                <meta
                    name='viewport'
                    content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0'
                />
                <link
                    rel='canonical'
                    href={ canonicalUrl }
                    key='canonical'
                />
                { /* Smartbanner */ }
                <link
                    rel='stylesheet'
                    href='https://cdnjs.cloudflare.com/ajax/libs/smartbanner.js/1.21.0/smartbanner.min.css'
                    integrity='sha512-iswz7l3kz12sdQCbM91Y+TITeWZPARoF+p9zHgXlR26ttlRAAiSEtDWL8vOJ66BBm6nkrF4WfmQy0bMRQekUtA=='
                    crossOrigin='anonymous'
                    referrerPolicy='no-referrer'
                />
                <meta
                    name='smartbanner:title'
                    content='Veryable: Business App'
                />
                <meta
                    name='smartbanner:author'
                    content='Download Veryable app for enhanced features and streamlined efficiency.'
                />
                <meta
                    name='smartbanner:icon-apple'
                    content='https://veryable-public-assets.s3.us-east-2.amazonaws.com/veryable_business_app_logo.png'
                />
                <meta
                    name='smartbanner:icon-google'
                    content='https://veryable-public-assets.s3.us-east-2.amazonaws.com/veryable_business_app_logo.png'
                />
                <meta
                    name='smartbanner:button'
                    content='VIEW'
                />
                <meta
                    name='smartbanner:button-url-apple'
                    content='https://apps.apple.com/us/app/veryable-business-app/id1513421404'
                />
                <meta
                    name='smartbanner:button-url-google'
                    content='https://play.google.com/store/search?q=veryable+business&c=apps&hl=en_US&gl=US'
                />
                <meta
                    name='smartbanner:enabled-platforms'
                    content='android,ios'
                />
                <meta
                    name='smartbanner:close-label'
                    content='Close'
                />
                <meta
                    name='smartbanner:custom-design-modifier'
                    content='ios'
                />
                <title>Veryable Business Portal</title>
                { /* Inject MUI styles first to match with the prepend: true configuration. */ }
            </head>
            <body>
                <AppRouterCacheProvider>
                    <NuqsAdapter>
                        <ThemeProvider theme={ theme }>
                            <CssBaseline />
                            <QueryClientProvider client={ queryClient }>
                                <MsalProvider instance={ pca }>
                                    <ErrorBoundary FallbackComponent={ GlobalErrorFallback }>
                                        <Provider store={ store }>
                                            <PersistGate
                                                loading={ null }
                                                persistor={ persistor }
                                            >
                                                <Suspense fallback={ null }>
                                                    <SnackbarProvider
                                                        Components={ {
                                                            default: BaseSnackbar
                                                            , success: BaseSnackbar
                                                            , error: BaseSnackbar
                                                            , warning: BaseSnackbar
                                                        } }
                                                        anchorOrigin={ {
                                                            horizontal: 'center'
                                                            , vertical: 'bottom'
                                                        } }
                                                        autoHideDuration={ 6000 }
                                                    >
                                                        { children }
                                                    </SnackbarProvider>
                                                </Suspense>
                                            </PersistGate>
                                        </Provider>
                                    </ErrorBoundary>
                                    <OfflineSnackbar isOffline={ isUserOffline } />
                                    <OnlineSnackbar
                                        isOnline={ isUserOnline }
                                        setIsOnline={ setUserOnline }
                                    />
                                    {
                                        process.env.NEXT_PUBLIC_APP_ENV === 'dev'
                                        && <ReactQueryDevtools buttonPosition='bottom-left' />
                                    }
                                    {
                                        process.env.NEXT_PUBLIC_ENVIRONMENT !== 'production'
                                    && ( process.env.NODE_ENV === 'production' || process.env.NEXT_PUBLIC_APP_ENV === 'test' )
                                    && (
                                        <Box sx={ { display: shouldShowReactQueryDevTools ? undefined : 'none' } }>
                                            <Suspense fallback={ null }>
                                                <ReactQueryDevtoolsProduction buttonPosition='bottom-left' />
                                            </Suspense>
                                        </Box>
                                    )
                                    }
                                </MsalProvider>
                            </QueryClientProvider>
                        </ThemeProvider>
                    </NuqsAdapter>
                    { /* Google Auth */ }
                    <Script
                        id='GoogleSignInScript'
                        src='https://accounts.google.com/gsi/client'
                        strategy='afterInteractive'
                    />
                    { /* Stripe Script */ }
                    <Script
                        id='StripeScript'
                        src={ process.env.NEXT_PUBLIC_ENVIRONMENT !== 'production'
                            ? 'https://js.stripe.com/v3/?advancedFraudSignals=false'
                            : 'https://js.stripe.com/v3/'
                        }
                        strategy='lazyOnload'
                    />
                    { /* HubSpot Embed Code */ }
                    <Script
                        id='hs-script-loader'
                        type='text/javascript'
                        async
                        defer
                        strategy='lazyOnload'
                        src='//js.hs-scripts.com/5382131.js'
                    />
                    { /* Google Analytics */ }
                    <Script
                        id='GATagScript'
                        strategy='afterInteractive'
                        src={ `https://www.googletagmanager.com/gtag/js?id=${ process.env.NEXT_PUBLIC_GA_ID }` }
                    />
                    { /* AppCues */ }
                    <Script
                        id='AppCuesScript'
                        src='//fast.appcues.com/203136.js'
                        strategy='afterInteractive'
                    />
                    { /* Google Analytics */ }
                    <Script
                        id='GAScript'
                        strategy='afterInteractive'
                        src='/gtag.js'
                    />
                    { /* Zendesk Widget Configuration */ }
                    <Script
                        id='ZendeskWidgetConfig'
                        src='/zendesk.js'
                        strategy='lazyOnload'
                    />
                    { /* Zendesk Widget Script */ }
                    <Script
                        id='ze-snippet'
                        src='https://static.zdassets.com/ekr/snippet.js?key=d1b73c93-ef9a-4730-b5a6-0ee552605140'
                        strategy='lazyOnload'
                    />
                    { /* Check if user is using IE */ }
                    <Script
                        id='IECheck'
                        src='/iecheck.js'
                        strategy='lazyOnload'
                    />
                    { /* Smartbanner */ }
                    {
                        isMdDown && !pathname?.includes( 'wfm-employee-schedule' )
                        && (
                            <Script
                                src='https://cdnjs.cloudflare.com/ajax/libs/smartbanner.js/1.21.0/smartbanner.min.js'
                                integrity='sha512-ILGJiIOOUUkOaNoevtDtu+q3k0BQ32K6uFixOLDv1vpAv7c/cNDmgFZkMvnkzvptBDDFk3MspEbVM3n1pTmVMA=='
                                crossOrigin='anonymous'
                                referrerPolicy='no-referrer'
                                strategy='afterInteractive'
                            />
                        )
                    }
                </AppRouterCacheProvider>
            </body>
        </html>
    );
}
